import React from 'react';

import Section from '../section';
import SummaryItem from '../summary-item';

const SectionVolunteering = ({ volunteering }) => {
  if (!volunteering.length) return null;

  return (
    <Section title="Volunteering">
      {volunteering.map((item) => (
        <SummaryItem
          key={item.name}
          name={item.name}
          description={item.description}
        />
      ))}
    </Section>
  );
};

export default SectionVolunteering;
